import { currencies, get_categories, get_search, get_types } from 'actions/announcement';
import Apartment from 'components/section-components/search-bar/apartment';
import Commercial from 'components/section-components/search-bar/commercial';
import DefaultType from 'components/section-components/search-bar/default-type';
import House from 'components/section-components/search-bar/house';
import Land from 'components/section-components/search-bar/land';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Dropdown, DropdownMenu } from 'reactstrap';
import { DropdownToggle } from 'reactstrap';
import { setSelectPlaces } from 'reducers/announcementReducer';

const SearchBar = (props) => {
  const dispatch = useDispatch();
  const { t } = props;
  const publicUrl = process.env.PUBLIC_URL;
  const filterIcon = publicUrl + '/assets/img/icons/filter-icon.png';
  // const [priceOpen, setPriceOpen] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);
  const [rentTypeOpen, setRentTypeOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [otherOpen, setOtherOpen] = useState(false);
  const [category, setCategory] = useState(props.data && props.data.cat ? props.data.cat : 1);
  const [filterModal, setFilterModal] = useState(false);
  const [mobileCategory, setMobileCategory] = useState(false);
  const [mobileRentType, setMobileRentType] = useState(false);
  const [mobileType, setMobileType] = useState(false);
  const [mobilePrice, setMobilePrice] = useState(false);
  const [mobileMore, setMobileMore] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [type, setType] = useState('property_type');
  const [rentType, setRentType] = useState('rent_type');
  const [centerCoordinates] = useState([]);
  const [categoryName, setCategoryName] = useState(
    (category === 1 && 'Sale') || (category === 2 && 'Rent') || 'property_for'
  );
  const [searchParams, setSearchParams] = useState({
    category: category,
    code: '',
    type: '',
    land_type: '',
    furniture: '',
    minArea: '',
    maxArea: '',
    minPrice: '',
    currency: '',
    maxPrice: '',
    minSize: '',
    maxSize: '',
    rooms: '',
    floor: '',
    storeys: '',
    building_type: '',
    condition: '',
    ceiling_height: '',
    bathroom: '',
    state: '',
    city: '',
    land_geometric_appearance: '',
    front_position: '',
    purpose: '',
    place: '1',
    start_date: null,
    end_date: null,
  });
  const [visible] = useState(true);

  const toggleMobileCategory = () => setMobileCategory((prevState) => !prevState);
  const toggleMobileRentType = () => setMobileRentType((prevState) => !prevState);
  const toggleMobileType = () => setMobileType((prevState) => !prevState);
  const toggleMobilePrice = () => setMobilePrice((prevState) => !prevState);
  const toggleMobileMore = () => setMobileMore((prevState) => !prevState);
  // const togglePrice = () => setPriceOpen(prevState => !prevState);
  const toggleType = () => setTypeOpen((prevState) => !prevState);
  const toggleRentType = () => setRentTypeOpen((prevState) => !prevState);
  const toggleCategory = () => setCategoryOpen((prevState) => !prevState);
  const toggleOther = () => setOtherOpen((prevState) => !prevState);

  const getTypes = useSelector((state) => state.type.types);
  const getCurrencies = useSelector((state) => state.announcement.currencies);
  const addresses = useSelector((state) => state.announcement.places);

  useEffect(() => {
    dispatch(get_categories());
    dispatch(get_types());
    dispatch(currencies());
    const address =
      localStorage.addresses && JSON.parse(localStorage.addresses).length > 0 ? JSON.parse(localStorage.addresses) : [];
    if (address.length) {
      dispatch(get_search(searchParams, ['place', address[address.length - 1].id]));
    } else {
      dispatch(get_search(searchParams, ['place', 1]));
    }
  }, [dispatch, category, centerCoordinates, searchParams]);

  const searchBarChange = (name, value, resetDateRange = false) => {
    name === 'category' && setCategory(value);
    setSearchParams({
      ...searchParams,
      [name]: value,
      ...(resetDateRange && { start_date: null, end_date: null }),
    });
    // Note: seems to be useless code making unnecessary request to BE
    // search([name, value]);
  };

  const search = (data) => {
    dispatch(get_search(searchParams, data));
  };

  const debouncedChangeHandler = useCallback(
    debounce((key, value) => {
      searchBarChange(key, value);
    }, 500),
    []
  );

  const onDismiss = (uid) =>
    function () {
      addresses.map((value, i) => {
        if (uid === value.id) addresses.splice(i, 1);
        return addresses;
      });
      localStorage.setItem('addresses', JSON.stringify(addresses));
      if (addresses.length > 0) {
        dispatch(setSelectPlaces([...addresses]));
      } else {
        dispatch(get_search(searchParams, ['place', 1]));
        dispatch(setSelectPlaces([]));
      }
    };

  const handleSelectPlace = (address) =>
    function () {
      addresses.forEach((value, index) => {
        if (value.id === address.id) {
          addresses.splice(index, 1);
        }
      });
      addresses.push({
        id: address.id,
        name: address.name,
        coordinates: address.coordinates,
        map_zoom: address.map_zoom,
        key: address.key,
      });
      localStorage.removeItem('addresses');
      localStorage.setItem('addresses', JSON.stringify(addresses));
      dispatch(setSelectPlaces([...addresses]));
      search(['place', address.id]);
    };

  return (
    <div className="searchbar-container">
      <div style={{ background: '#F6F6F7', padding: 10 }}>
        <div className="tab-pane fade show">
          <div className="rld-main-search ">
            <div className="main-search-cont-div-cont half-width">
              <div className="main-search-cont-div ">
                {addresses.map((value, i) => (
                  <Alert
                    key={i}
                    color="light"
                    isOpen={visible}
                    toggle={onDismiss(value.id)}
                    className="main-search-cont-input m-1 old_search_list"
                  >
                    <div onClick={handleSelectPlace(value)}>
                      <i className="fa fa-search mr-2" style={{ color: '#BE1E2D' }} /> {value.name}
                    </div>
                  </Alert>
                ))}
              </div>
            </div>
            <div className="main-search-cont-div-cont-filter half-width">
              <div className="search-bar-components mt-1 mr-4 filter-desktop full-width">
                <div className="">
                  <Dropdown isOpen={categoryOpen} toggle={toggleCategory}>
                    <DropdownToggle className="search-bar-dropdown-toggle search-bar-components">
                      <div className="sq-single-select">
                        {t(categoryName)}
                        <img
                          className={categoryOpen ? 'search-bar-arrow-open' : 'search-bar-arrow'}
                          src={publicUrl + '/assets/img/icons/arrow-down.png'}
                          alt="..."
                        />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="search-bar-dropdown-buy">
                      <div className="rld-single-input mg-top-10">
                        <div className="sq-single-select">
                          <ul style={{ paddingLeft: 0 }}>
                            {/* <ol className={'search-bar-types-style'}
                                                        onClick={() => {
                                                            toggleCategory()
                                                            setCategoryName('property_for')
                                                            searchBarChange('category', '')
                                                        }}>
                                                        {t('property_for')}
                                                    </ol> */}
                            <ol
                              className={'search-bar-types-style'}
                              onClick={() => {
                                toggleCategory();
                                setCategoryName('Sale');
                                searchBarChange('category', 1, true);
                              }}
                            >
                              {t('Sale')}
                            </ol>
                            <ol
                              className={'search-bar-types-style'}
                              onClick={() => {
                                toggleCategory();
                                setCategoryName('Rent');
                                searchBarChange('category', 2, true);
                              }}
                            >
                              {t('Rent')}
                            </ol>
                          </ul>
                        </div>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                {categoryName === 'Rent' && (
                  <div className="filter-modal-inner">
                    <Dropdown isOpen={rentTypeOpen} toggle={toggleRentType}>
                      <DropdownToggle className="search-bar-dropdown-toggle search-bar-components ">
                        <div className="sq-single-select filter-modal-single modal-arrow">
                          {t(rentType)}
                          <img
                            style={{ width: 20 }}
                            className={mobileRentType ? 'search-bar-arrow-open' : 'search-bar-arrow'}
                            src={publicUrl + '/assets/img/icons/arrow-down.png'}
                            alt="..."
                          />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu className="search-bar-dropdown-type">
                        <div className="rld-single-input mg-top-10">
                          <div className="sq-single-select">
                            <ul style={{ paddingLeft: 0 }}>
                              <ol
                                className={'search-bar-types-style'}
                                onClick={() => {
                                  toggleRentType();
                                  setRentType('rent_type');
                                  searchBarChange('rent_type', '', true);
                                }}
                              >
                                {t('rent_type')}
                              </ol>
                              <ol
                                className={'search-bar-types-style'}
                                onClick={() => {
                                  toggleRentType();
                                  setRentType('daily_rent');
                                  searchBarChange('rent_type', 'daily_rent', true);
                                }}
                              >
                                {t('daily_rent')}
                              </ol>
                              <ol
                                className={'search-bar-types-style'}
                                onClick={() => {
                                  toggleRentType();
                                  // ForSearch
                                  setRentType('monthly_rent');
                                  searchBarChange('rent_type', 'monthly_rent', true);
                                }}
                              >
                                {t('monthly_rent')}
                              </ol>
                            </ul>
                          </div>
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}
                <div className="">
                  <Dropdown isOpen={typeOpen} toggle={toggleType}>
                    <DropdownToggle className="search-bar-dropdown-toggle search-bar-components">
                      <div className="sq-single-select">
                        {type === 'property_type'
                          ? t(type)
                          : type.translations.map((value, i) => {
                              return (localStorage.i18nextLng === 'us' && value.locale === 'en') ||
                                value.locale === localStorage.i18nextLng
                                ? value.name
                                : null;
                            })}
                        <img
                          className={typeOpen ? 'search-bar-arrow-open' : 'search-bar-arrow'}
                          src={publicUrl + '/assets/img/icons/arrow-down.png'}
                          alt="..."
                        />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="search-bar-dropdown-type">
                      <div className="rld-single-input mg-top-10">
                        <div className="sq-single-select">
                          <ul style={{ paddingLeft: 0 }}>
                            {getTypes.map((value, key) => (
                              <ol
                                className={'search-bar-types-style'}
                                key={key}
                                onClick={() => {
                                  toggleType();
                                  setType(value);
                                  searchBarChange('type', value.id);
                                }}
                              >
                                {value.translations.map((value, i) => {
                                  return (localStorage.i18nextLng === 'us' && value.locale === 'en') ||
                                    value.locale === localStorage.i18nextLng
                                    ? value.name
                                    : null;
                                })}
                              </ol>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="">
                  <Dropdown isOpen={otherOpen} toggle={toggleOther}>
                    <DropdownToggle className="search-bar-dropdown-toggle search-bar-components">
                      <div className="sq-single-select">
                        {t('more')}
                        <img
                          className={otherOpen ? 'search-bar-arrow-open' : 'search-bar-arrow'}
                          src={publicUrl + '/assets/img/icons/arrow-down.png'}
                          alt="..."
                        />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="search-bar-dropdown-more">
                      {!type.id && (
                        <DefaultType
                          setSearchParams={setSearchParams}
                          searchBarChange={searchBarChange}
                          getCurrencies={getCurrencies}
                          t={t}
                          searchParams={searchParams}
                        />
                      )}
                      {type.id === 1 && <House t={t} searchParams={searchParams} searchBarChange={searchBarChange} />}
                      {type.id === 2 && (
                        <Apartment t={t} searchParams={searchParams} searchBarChange={searchBarChange} />
                      )}
                      {type.id === 3 && (
                        <Commercial t={t} searchParams={searchParams} searchBarChange={searchBarChange} />
                      )}
                      {type.id === 4 && <Land t={t} searchParams={searchParams} searchBarChange={searchBarChange} />}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <input
                  className="code-input form-control"
                  type="text"
                  name="code"
                  placeholder={t('code')}
                  value={searchInput}
                  onChange={(event) => {
                    setSearchInput(event.target.value);
                    debouncedChangeHandler('code', event.target.value);
                  }}
                />
                <button
                  className={'btn btn-danger px-3 ml-4'}
                  onClick={() => {
                    localStorage.removeItem('addresses');
                    window.location.reload(false);
                  }}
                >
                  <i className="fa fa-repeat" aria-hidden="true"></i>
                  <span style={{ paddingLeft: 10 }}>{t('reset')}</span>
                </button>
              </div>
              <div className="search-bar-components filter-mobile">
                <input
                  className="code-input form-control"
                  type="text"
                  name="code"
                  placeholder={t('code')}
                  value={searchInput}
                  onChange={(event) => {
                    setSearchInput(event.target.value);
                    debouncedChangeHandler('code', event.target.value);
                  }}
                />
                <img
                  src={filterIcon}
                  onClick={() => {
                    setFilterModal((v) => !v);
                  }}
                  height={16}
                  alt={'...'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {filterModal ? (
        <div className={'filter-modal-container'}>
          <div className={'close-button-container'}>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => {
                setFilterModal((v) => !v);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="filter-modal">
            <div className="filter-modal-inner">
              <Dropdown isOpen={mobileCategory} toggle={toggleMobileCategory}>
                <DropdownToggle className="search-bar-dropdown-toggle search-bar-components ">
                  <div className="sq-single-select filter-modal-single modal-arrow">
                    {t(categoryName)}
                    <img
                      style={{ width: 20 }}
                      className={mobileCategory ? 'search-bar-arrow-open' : 'search-bar-arrow'}
                      src={publicUrl + '/assets/img/icons/arrow-down.png'}
                      alt="..."
                    />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="search-bar-dropdown-type">
                  <div className="rld-single-input mg-top-10">
                    <div className="sq-single-select">
                      <ul style={{ paddingLeft: 0 }}>
                        <ol
                          className={'search-bar-types-style'}
                          onClick={() => {
                            toggleMobileCategory();
                            setCategoryName('property_for');
                            searchBarChange('category', '');
                          }}
                        >
                          {t('property_for')}
                        </ol>
                        <ol
                          className={'search-bar-types-style'}
                          onClick={() => {
                            toggleMobileCategory();
                            setCategoryName('Sale');
                            searchBarChange('category', 1);
                          }}
                        >
                          {t('Sale')}
                        </ol>
                        <ol
                          className={'search-bar-types-style'}
                          onClick={() => {
                            toggleMobileCategory();
                            setCategoryName('Rent');
                            searchBarChange('category', 2);
                          }}
                        >
                          {t('Rent')}
                        </ol>
                      </ul>
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
            {categoryName === 'Rent' && (
              <div className="filter-modal-inner">
                <Dropdown isOpen={mobileRentType} toggle={toggleMobileRentType}>
                  <DropdownToggle className="search-bar-dropdown-toggle search-bar-components ">
                    <div className="sq-single-select filter-modal-single modal-arrow">
                      {t(rentType)}
                      <img
                        style={{ width: 20 }}
                        className={mobileRentType ? 'search-bar-arrow-open' : 'search-bar-arrow'}
                        src={publicUrl + '/assets/img/icons/arrow-down.png'}
                        alt="..."
                      />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className="search-bar-dropdown-type">
                    <div className="rld-single-input mg-top-10">
                      <div className="sq-single-select">
                        <ul style={{ paddingLeft: 0 }}>
                          <ol
                            className={'search-bar-types-style'}
                            onClick={() => {
                              toggleMobileRentType();
                              setRentType('rent_type');
                              searchBarChange('rent_type', '', true);
                            }}
                          >
                            {t('rent_type')}
                          </ol>
                          <ol
                            className={'search-bar-types-style'}
                            onClick={() => {
                              toggleMobileRentType();
                              setRentType('daily_rent');
                              searchBarChange('rent_type', 'daily_rent', true);
                            }}
                          >
                            {t('daily_rent')}
                          </ol>
                          <ol
                            className={'search-bar-types-style'}
                            onClick={() => {
                              toggleMobileRentType();
                              setRentType('monthly_rent');
                              searchBarChange('rent_type', 'monthly_rent', true);
                            }}
                          >
                            {t('monthly_rent')}
                          </ol>
                        </ul>
                      </div>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            <div className="">
              <Dropdown isOpen={mobileType} toggle={toggleMobileType}>
                <DropdownToggle className="search-bar-dropdown-toggle search-bar-components">
                  <div className="sq-single-select filter-modal-single modal-arrow">
                    {type === 'property_type'
                      ? t(type)
                      : type.translations.map((value, i) => {
                          return (localStorage.i18nextLng === 'us' && value.locale === 'en') ||
                            value.locale === localStorage.i18nextLng
                            ? value.name
                            : null;
                        })}
                    <img
                      style={{ width: 20 }}
                      className={mobileType ? 'search-bar-arrow-open' : 'search-bar-arrow'}
                      src={publicUrl + '/assets/img/icons/arrow-down.png'}
                      alt="..."
                    />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="search-bar-dropdown-type">
                  <div className="rld-single-input mg-top-10">
                    <div className="sq-single-select">
                      <ul style={{ paddingLeft: 0 }}>
                        <ol
                          className={'search-bar-types-style'}
                          onClick={() => {
                            toggleMobileType();
                            setType('property_type');
                            searchBarChange('type', '');
                          }}
                        >
                          {t('property_type')}
                        </ol>
                        {getTypes.map((value, key) => (
                          <ol
                            className={'search-bar-types-style'}
                            key={key}
                            onClick={() => {
                              toggleMobileType();
                              setType(value);
                              searchBarChange('type', value.id);
                            }}
                          >
                            {value.translations.map((value, i) => {
                              return (localStorage.i18nextLng === 'us' && value.locale === 'en') ||
                                value.locale === localStorage.i18nextLng
                                ? value.name
                                : null;
                            })}
                          </ol>
                        ))}
                      </ul>
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="">
              <Dropdown isOpen={mobilePrice} toggle={toggleMobilePrice}>
                <DropdownToggle className="search-bar-dropdown-toggle search-bar-components">
                  <div className="sq-single-select filter-modal-single modal-arrow">
                    {t('price')}
                    <img
                      style={{ width: 20 }}
                      className={mobilePrice ? 'search-bar-arrow-open' : 'search-bar-arrow'}
                      src={publicUrl + '/assets/img/icons/arrow-down.png'}
                      alt="..."
                    />
                  </div>
                </DropdownToggle>
                <DropdownMenu className=" search-bar-dropdown-more">
                  <div className="container">
                    <div className="rld-single-input mg-top-10">
                      <div className="sq-single-select row">
                        <div className="col-md-6 col-xl-6 col-lg-6">
                          <label>{t('min')}</label>
                          <input
                            value={searchParams.minPrice ? searchParams.minPrice : ''}
                            type="text"
                            placeholder={t('max')}
                            min="0"
                            name="minPrice"
                            onChange={(event) => {
                              if (event.target.value >= 0) {
                                searchBarChange(event.target.name, event.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-6 col-xl-6 col-lg-6">
                          <label>{t('max')}</label>
                          <input
                            value={searchParams.maxPrice ? searchParams.maxPrice : ''}
                            name="maxPrice"
                            onChange={(event) => {
                              if (event.target.value >= 0) {
                                searchBarChange(event.target.name, event.target.value);
                              }
                            }}
                            type="text"
                            placeholder={t('max')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="rld-single-input mg-top-30">
                      <div className="sq-single-select">
                        <select
                          className="select single-select add-property-select"
                          name="currency"
                          onChange={(event) => {
                            searchBarChange(event.target.name, event.target.value);
                          }}
                        >
                          <option value="">{t('choose')}</option>
                          {getCurrencies.map((value, key) => {
                            return (
                              <option key={key} value={value.id}>
                                {value.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="">
              <Dropdown isOpen={mobileMore} toggle={toggleMobileMore}>
                <DropdownToggle className="search-bar-dropdown-toggle search-bar-components">
                  <div className="sq-single-select filter-modal-single modal-arrow">
                    {t('more')}
                    <img
                      style={{ width: 20 }}
                      className={mobileMore ? 'search-bar-arrow-open' : 'search-bar-arrow'}
                      src={publicUrl + '/assets/img/icons/arrow-down.png'}
                      alt="..."
                    />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="search-bar-dropdown-more">
                  {!type.id && (
                    <DefaultType
                      setSearchParams={setSearchParams}
                      searchBarChange={searchBarChange}
                      getCurrencies={getCurrencies}
                      t={t}
                      searchParams={searchParams}
                    />
                  )}
                  {type.id === 1 && <House t={t} searchParams={searchParams} searchBarChange={searchBarChange} />}
                  {type.id === 2 && <Apartment t={t} searchParams={searchParams} searchBarChange={searchBarChange} />}
                  {type.id === 3 && <Commercial t={t} searchParams={searchParams} searchBarChange={searchBarChange} />}
                  {type.id === 4 && <Land t={t} searchParams={searchParams} searchBarChange={searchBarChange} />}
                  <div className={'justify-center'}>
                    <button className={'btn btn-danger px-3'} onClick={() => toggleMobileMore()}>
                      {t('search')}
                    </button>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div
              className=""
              onClick={() => {
                localStorage.removeItem('addresses');
                window.location.reload(false);
              }}
            >
              <i className="fa fa-repeat" aria-hidden="true"></i>
              <span style={{ paddingLeft: 20 }}>{t('reset')}</span>
            </div>
          </div>
          <div className="filter-modal-save-button-container justify-center mt-3">
            <button className=" filter-modal-save-button btn" onClick={() => setFilterModal((v) => !v)}>
              {t('search')}
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SearchBar;
