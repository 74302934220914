export const TRANSLATIONS_US = {
  add_listing: 'Add Announcement',
  login: 'Sign In',
  logout: 'Sign Out',
  banner_title1: 'One-stop real estate platform',
  banner_title2: '',
  banner_text1: 'We will help you buy, sell or rent any real estate ',
  banner_text2: 'quickly and safely',
  buy: 'Buy',
  sell: 'Sell',
  rent: 'Rent',
  sell_or_rent: 'Sell / Rent',
  rent_type: 'Rental type',
  rental_period: 'Rental period',
  my_bookings: 'My Bookings',
  booking_requests: 'Booking Requests',
  book: 'Book',
  cancel_booking_confirmation: 'You want to cancel your booking?',
  reject_booking_confirmation: 'You want to reject this booking?',
  confirm_booking_confirmation: 'You want to confirm this booking?',
  booking_confirmation: 'Confirmation: Are these your selected dates for booking?',
  booked_successfully: 'Your request is under consideration.',
  construction: 'New Construction',
  buy_text: 'Buy any real estate in the shortest period of time.',
  sell_text: 'We will help you to sell or rent your property, with the best of the proposed options․',
  rent_text: 'Rent any real estate according to your requirements.',
  construction_text: 'Find the best solutions for buying real estate from the primary market.',
  reviewByLocal_title: 'Review by local area',
  reviewByLocal_desc1:
    'Find a home or space from our search bar. Enter a specific location where you want to buy,sell or rent a propery',
  reviewByLocal_desc2: 'Simple!',
  reviewByLocal_btn: 'Get Started',
  contact_us: 'ContactInfo Us',
  notification: 'Notification',
  name: 'Name',
  surname: 'Surname',
  property_name: 'Title of property',
  property_price: 'Property price',
  number_of_bedrooms: 'Number of Bedrooms',
  property_size: 'Property Size',
  ceiling_height: 'Ceiling height',
  number_of_bathrooms: 'Bathroom',
  floor: 'Floor',
  floors: 'Floors',
  condition: 'Condition',
  storeys: 'Number of floors',
  building_type: 'Building type',
  address: 'Address',
  info_address: 'Choose address from map',
  upload_photos: 'Upload photos',
  min: 'Min',
  max: 'Max',
  images: 'images',
  question_about_price: 'What price do you consider for selling this property?',
  price: 'Price',
  add_info: 'Additional Information',
  facilities: 'Communal improvements',
  property_description: 'Description',
  description: 'Description',
  yes: 'Yes',
  no: 'No',
  question_about_broker: 'Do you need an agent to sell or rent your property?',
  question_about_change_broker: 'Do you need another agent to sell or rent your property?',
  agree_question: 'I agree to the proposed general terms and conditions.',
  publish_listing: 'Publish',
  general_description: 'General Description',
  text_example_property: 'Preview',
  add_more: '+ Add More',
  baths: 'Bathroom',
  beds: 'Number of bedrooms',
  list_property_for: 'Purpose of announcement',
  what_do_you_want_to_sell: 'What do you want to sell?',
  what_do_you_want_to_rent: 'What do you want to rent out?',
  zero_condition: 'Zero',
  good: 'Good',
  excellent: 'Excellent',
  basement: 'Basement',
  monolith: 'Monolith',
  stone: 'Stone',
  panel: 'Panel',
  other: 'Other',
  profile: 'Account',
  my_announcements: 'Activity',
  favorites: 'Favorites',
  settings: 'Settings',
  request: 'Request',
  comment: 'Comment',
  send: 'Send',
  see_all_agents: 'View All Agents',
  land_area: 'Area near the house',
  area: 'Living area',
  land_geometric: 'Land geometric appearance',
  purpose: 'Purpose of use',
  public_construction_of_settlements: 'Public construction of settlements',
  residential_construction_of_settlements: 'Residential construction of settlements',
  degrees: 'Degrees',
  smooth: 'Smooth',
  mixed_construction_of_settlements: 'Mixed construction of settlements',
  front_position: 'Front position',
  primary_and_secondary: 'Primary and secondary',
  secondary: 'Secondary',
  primary: 'Primary',
  front_position_length: 'Front position length',
  road_type: 'Road type',
  asphalt: 'Asphalt',
  ground: 'Ground',
  fence_type: 'Fence type',
  partly_fenced: 'Partly fenced',
  stone_fence: 'Stone fence',
  no_fence: 'Has no fence',
  infrastructure: 'Infrastructure',
  all_available: 'All available',
  no_communication: 'Has no communication',
  all_available_except_irrigation_water: 'All available except irrigation water',
  open_balcony: 'Open balcony',
  balcony: 'Balcony',
  close_balcony: 'Closed balcony',
  no_balcony: 'No balcony',
  before: 'Up to',
  cover: 'Coating',
  degree: 'Degree of accident',
  daily_rent: 'Daily',
  monthly_rent: 'Monthly',
  wood: 'Wood',
  reinforced_concrete: 'Monolith',
  see_the_list_of: 'Contact the most experienced real estate agencies.',
  estate_agencies: 'Real estate agencies',
  our_partners: 'Our partners',
  see_the_list_of_partners: 'Get acquainted with the companies cooperating with us',
  see_the_agents: 'Trusted real estate agents',
  see_the_agents_who: 'Make secure transactions with the most trustworthy agents.',
  property_type: 'Property type',
  property_for: 'Looking for',
  certificate: 'Certificate',
  support: 'Support',
  address_1sq: 'Anastas Mikoyan 2/3, Yerevan',
  footer_agencies: 'Agencies',
  see_all_estate_agencies: 'All agencies',
  footer_announcements: 'Announcements',
  faq: 'FAQ',
  payment_and_tax: 'Payment and tax',
  contacts: 'Contacts',
  about: 'About',
  about_me: 'About',
  support_: 'Support',
  help_center: 'Help center',
  find_agent: 'Find an agent',
  features: 'Details',
  privacy_policy: 'Privacy policy',
  copyright: '2021, 1SQ Realty. All rights reserved.',
  sofast: 'SoFast Solutions Inc.',
  powered_by: 'Powered by ',
  suffix_for_powered: '',
  price_error: 'The price field is required',
  price_is_require: 'The price is required ',
  area_is_require: 'The land area is required',
  front_position_length_is_require: 'Front position length is require',
  address_is_require: 'The address is required',
  building_is_require: 'Building is required',
  description_is_require: 'The description is required',
  min_3_image: 'Min 3 image',
  agree_is_require: 'The agreement is required',
  property_name_is_require: 'Property name is require',
  require: 'Required',
  choose: 'Choose',
  building: 'Building',
  bath: 'Bath.',
  bed: 'Bed.',
  bad: 'Bad',
  middle: 'Average',
  rent_price: 'Rent',
  year: 'Property age',
  certificate_info: 'Certificate of ownership (visible only to site administrator)',
  property_name_example: 'Name',
  there_is_building: 'There is a building',
  there_is_not_building: 'There is no building',
  building_number: 'App.',
  yearly_rent: 'Yearly',
  more: 'Details',
  available: 'Availability of places in residence:',
  available2: 'Available in:',
  broker_owner: 'Broker / Owner',
  owner: 'Owner',
  broker: 'Broker',
  reset: 'Update',
  rooms: 'Rooms:',
  parking: 'Parking spaces:',
  floor_height: 'Height of the floor:',
  m: 'm',
  lot: 'Lot:',
  elevator: 'Elevator:',
  gas: 'Gas:',
  pharmacy: 'Pharmacy:',
  garden: 'Garden:',
  mall: 'Mall:',
  region: 'Region',
  aragatsotn: 'Aragatsotn',
  ararat: 'Ararat',
  armavir: 'Armavir',
  gegharkunik: 'Gegharkunik',
  kotayk: 'Kotayk',
  lori: 'Lori',
  shirak: 'Shirak',
  syunik: 'Syunik',
  tavush: 'Tavush',
  vayoc_dzor: 'Vayoc dzor',
  yerevan: 'Yerevan',
  company: 'Company',
  service_type: ' Service Type',
  broker_page_title: 'Real estate agents in Armenia',
  top_agencies: 'The best real estate agencies',
  rating: 'Rating',
  continue: 'Continue',
  cancel: 'Cancel',
  close: 'Close',
  select_auto: 'Select Automatically',
  account: 'Account',
  your_property: 'Your property',
  rentings: 'Rentings',
  offers_and_closings: 'Offers and Closings',
  role: 'Role',
  created_date: 'Created date',
  status: 'Status',
  feedback: 'Feedback',
  reviews: 'Reviews',
  professional_information: 'Professional Information',
  cell_phone: 'Cell phone',
  websites: 'Social networks',
  member_since: 'Member since',
  licenses: 'Licenses',
  overall_rating: 'Overall Rating',
  call_now: 'Call',
  property: 'Property',
  are_you_sure: 'Are you sure?',
  you_want_to_delete: 'You want to delete announcement?',
  welcome: 'Welcome to 1SQ!',
  sign_in: 'Sign In',
  sign_up: 'New account',
  continue_registration: 'Continue Registration',
  email: 'Email',
  password: 'Password',
  forgot_password: 'Forgot your Password?',
  confirm_password: 'Confirm password',
  first_name: 'First name',
  last_name: 'Last name',
  send_link: 'Send Link',
  name_lastname: 'First Name Last Name',
  request_description: 'Please send me more information about this property.',
  edit_profile: 'Edit Profile',
  edit: 'Edit',
  contact_information: 'Contact Information',
  phone_number: 'Phone',
  personal_information: 'Personal Information',
  new_password: 'New password',
  sign_in_security: 'Sign in & Security',
  general_area: 'Area',
  land_type: 'Land type',
  property_place: 'Property place',
  into_building: 'Into building',
  out_of_building: 'Out of building',
  sewer: 'Sewerage',
  distance_from_metro_station: 'Distance from metro station',
  distance_from_medical_center: 'Nearest hospital',
  distance_from_stations: 'Nearest bus stop',
  distance_from_supermarket: 'Nearest supermarket',
  distance_from_school: 'Nearest school',
  distance_from_kindergarten: 'Nearest kindergarten',
  distance_from_pharmacy: 'Nearest pharmacy',
  furniture: 'Furniture',
  individual: 'Separate',
  centralised: 'Centralized',
  shops: 'Shops',
  offices: 'Offices',
  services: 'Services',
  update: 'Update',
  search: 'Search',
  modal_title_1: 'Find offers',
  modal_title_2: 'Select the desired region and view the most relevant offers using the map.',
  phone_number_verification: 'Verify Your Phone Number',
  verify: 'Confirm',
  get_code: 'Verify with SMS',
  verified_text: 'Thank You, Your Phone Number Verified',
  phone: 'Phone number',
  code: 'Code',
  verification_code: 'Verification code',
  sale: 'Sale',
  start_date: 'Start date',
  end_date: 'End Date',
  accept: 'Accept',
  main_image: 'Main image',
  edit_listing: 'Edit',
  basic_info: 'Information',
  email_address: 'Email Address',
  email_not_valid: 'Invalid e-mail',
  password_not_valid: 'Invalid password',
  confirm_password_error: 'Password does not match',
  password_confirmation_require_error: 'Confirming password is required',
  first_name_error: 'First name is required',
  last_name_error: 'Last name is required',
  email_error: 'User with current e-mail already exists',
  email_require_error: 'Email field is required',
  password_require_error: 'Password field is required',
  password_min_error: 'The password must contain letters and digits, min. 8 symbols',
  password_info: '(Must contain letters and digits, min. 8 symbols)',
  email_or_password_wrong: 'Incorrect e-mail or password',
  password_required: '(Old password is required for setting the new)',
  condominium: 'Condominium fee',
  new_password_required: '(Password is required)',
  no_favorite: 'No Results',
  no_result: 'No Results',
  apartments: 'Apartments & floors',
  renovation: 'Renovation',
  auto_choose: 'Automatic choice',
  choose_agent: 'Attach to agent',
  currency: 'Currency',
  broker_choose_text: 'Your announcement will be trusted agent, who will choose it first.',
  finish_listing: 'Are you sure you want to publish the announcement?',
  verify_listing: 'Your request is under consideration. Please wait for a reply in 24 hours.',
  overview: 'Overview',
  today: 'Today',
  contact_agent: 'Contact agent',
  contact_owner: 'Contact owner',
  published_by: 'Published by',
  view_more: 'View more',
  const_agency: 'Agency',
  search_places: 'Search․․․',
  similar_listing: 'Similar Listings',
  additional: 'Additional',
  no_metro: 'No metro station',
  busy_date: 'Busy days',
  no_sewer: 'Absent',
  message_sending: 'Your message has been sent.',
  description_size_error: 'Max 500 letters',
  back: 'Back',
  request_text: 'The request sent to your email',
  ready: 'Ready',
  sold: 'Sold',
  air_polution: 'Air Pollution',
  air_polution_types: {
    1: 'Good',
    2: 'Fair',
    3: 'Moderate',
    4: 'Poor',
    5: 'Very Poor',
  },
  message: 'Message',
  messages: 'Messages',
  const_percent_text: 'Work on the project continues.',
  reset_password: 'Reset Password',
  live_video: 'Online watching',
  companies: 'Companies',
  as_an_agent: 'Register as an agent/broker',
  no_messages: 'No messages',
  sort_by: 'Sort by',
  price_high_low: 'Price decreasing',
  price_low_high: 'Price ascending',
  date_newest_first: 'Date (newest first)',
  date_oldest_first: 'Date (oldest first)',
  furniture_available: 'Available',
  furniture_no_available: 'Not available',
  send_request: 'Send Request',
  request_success_text: 'Thank you for contacting us, we will respond within three business days.',
  all: 'All',
  completion_deadlines: 'Deadlines',
  finished: 'Finished',
  current: 'Current',
  listing_limit_error: 'You already add 5 property',
  account_verified: 'Account Verified',
  gallery: 'Gallery',
  mark_all_as_read: 'Mark all as read',
  choose_an_agent: 'Choose an Agent',
  phone_required: 'The phone field is required',
  phone_unique: 'The phone number is already registered',
  results: 'results',
  unverified_announcements: 'Unverified',
  archived_announcements: 'Archive',
  open_map: 'Open map',
  open_list: 'Open List',
  user_not_exist: 'User does not exist',
  complete: 'Complete',
  completed: 'Completed',
  pending: 'Pending',
  confirmed: 'Confirmed',
  cancelled: 'Cancelled',
  rejected: 'Rejected',
  reject: 'Reject',
  archive: 'Archive',
  renew: 'Renew',
  archived: 'Archived',
  comments: 'Comments',
  rate_the_agent: 'Rate the agent',
  choose_a_company: 'Select an agency',
  choose_auto: 'Automatic selection',
  auto_choose_broker: 'Automatic',
  choose_auto_broker: 'selection',
  properties: 'Properties',
  brokers: 'Agents',
  new_building: 'New building',
  contact_company: 'Contact the agency',
  meta_home_title: 'Rent Apartment | Apartments For Sale | Real Estate | 1SQ Realty',
  meta_home_title1: '1SQ Realty',
  meta_home_description:
    '1SQ Realty will help you buy, sell or rent any real estate both in Yerevan and in the regions. For details: +374 44 800 455.',
  meta_agents_list: 'Real Estate Agents | Apartments For Sale And Rent | 1SQ Realty',
  meta_agents_list_description:
    '1SQ Realty cooperates with the best agents and agencies in the Armenian real estate market, creating the most reliable online platform in the sphere.',
  meta_announcements: 'Real Estate Announcements | Rent Home | 1SQ Realty',
  meta_announcements_description:
    'Post free announcements on the largest real estate platform in Armenia. Find a buyer or tenant in the shortest period of time.',
  meta_agencies_list: 'Real Estate Agencies in Armenia | 1SQ Realty',
  meta_agencies_list_description:
    '1SQ Realty registers only trusted agencies known as the best in the Armenian real estate market.',
  meta_construction: 'Apartments from Construction | Real Estate For Sale | 1SQ Realty',
  meta_construction_description:
    'Find new build apartments here. 1SQ Realty allows you to see all the details of the construction and renovation of your future apartment.',
  meta_agency_info: 'Real Estate Agency | 1SQ Realty',
  choose_currency: 'Choose Currency',
  mortgage: 'Mortgage calculator',
  salary: 'Salary calculator',
  persent: 'Annual percent',
  loan_amount: 'Loan amount',
  loan_prepayment: 'Down payment',
  it_tax: 'Do you work in an IT company with tax privileges?',
  income_tax: 'Income tax',
  IT: 'IT',
  social_fee: 'Social fee',
  stamp_duty: 'Stamp duty',
  net_balance: 'Net balance:',
  guyqahark: 'Mortgage calculator',
  grafik: 'Visualize',
  business_analitica: 'Business analytics',
  guyqi_ekamtaberutyun: 'Profitability',
  rent_range: 'Rent price',
  business_info: 'Calculate how many years it will take to be able to buy the same from your apartment.',
  month: 'Months',
  mortgage_calculator: 'Mortgage calculator',
  term: 'Terms',
  monthly_fee: 'Monthly fee',
  annual_interest_rate: 'Annual fee',
  appartment_price: 'Apt. price',
  payment: 'Down payment',
  change: 'Change',
  calculate: 'Calculate',
  fee: 'Fee',
  mother: 'Principal amount',
  annuity: 'Monthly amount',
  average_value: 'Average price',
  mg_warning: '"Months" input is required',
  individual_user: 'Individual user',
};
