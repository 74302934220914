export const TRANSLATIONS_AM = {
  add_listing: 'Տեղադրել հայտարարություն',
  login: 'Մուտք',
  logout: 'Դուրս գալ',
  banner_title1: 'Անշարժ գույքի ամենաունիվերսալ հարթակը',
  banner_title2: '',
  banner_text1: 'Մենք կօգնենք Ձեզ գնել, վաճառել կամ վարձակալել ցանկացած ',
  banner_text2: 'անշարժ գույք՝ արագ և ապահով։',
  buy: 'Գնել',
  sell: 'Վաճառել',
  rent: 'Վարձակալել',
  Rent: 'Վարձակալել',
  rent_price: 'Վարձ',
  sell_or_rent: 'Վաճ․ / Վարձ․',
  rent_type: 'Վարձակալության տեսակը',
  rental_period: 'Վարձակալության ժամկետը',
  my_bookings: 'Իմ Ամրագրումները',
  booking_requests: 'Ամրագրման Հարցումներ',
  book: 'Ամրագրել',
  cancel_booking_confirmation: 'Ցանկանու՞մ եք չեղարկել Ձեր ամրագրումը:',
  reject_booking_confirmation: 'Ցանկանու՞մ եք մերժել ամրագրումը:',
  confirm_booking_confirmation: 'Ցանկանու՞մ եք հաստատել ամրագրումը:',
  booking_confirmation: 'Հաստատում. սրանք Ձեր ընտրած ամսաթվե՞րն են ամրագրման համար:',
  booked_successfully: 'Ձեր հայտը հաստատման փուլում է։',
  buy_text: 'Գնեք ցանկացած անշարժ գույք՝ ամենասեղմ ժամկետներում։',
  sell_text: 'Վաճառեք կամ վարձակալության հանձնեք Ձեր անշարժ գույքը՝ տեղադրելով անվճար հայտարարություն։',
  rent_text: 'Վարձակալեք ցանկացած անշարժ գույք՝ Ձեր նախընտրելի չափանիշներին համապատասխան։',
  construction: 'Բնակարան կառուցապատողից',
  construction_text: 'Գտեք առաջնային շուկայից անշարժ գույքի ձեռքբերման ամենահարմար լուծումներն այստեղ։',
  contact_us: 'Հետադարձ Կապ',
  reviewByLocal_title: 'Տարածքային ընտրություն',
  reviewByLocal_desc1: 'Նշի՛ր քո նախընտրած տեղանքն, ու մենք կառաջարկենք քեզ անշարժգույքի լավագույն տարբերակները։',
  reviewByLocal_desc2: '',
  reviewByLocal_btn: 'Սկսել',
  name: 'Անուն',
  surname: 'Ազգանուն',
  property_name: 'Գույքի անվանում ',
  number_of_bedrooms: 'Ննջասենյակների քանակ',
  property_size: ' Գույքի տարածք',
  ceiling_height: 'Առաստաղի բարձրություն ',
  number_of_bathrooms: 'Սանհանգույց',
  floor: 'Հարկ ',
  floors: 'Հարկեր ',
  condition: 'Վիճակ ',
  storeys: 'Հարկերի քանակ',
  building_type: 'Շինության տիպը ',
  address: 'Հասցե',
  info_address: 'Ընտրել հասցեն քարտեզից',
  upload_photos: 'Ներբեռնել նկարներ',
  min: 'Մին․',
  max: 'Մաքս․',
  images: 'Նկարներ',
  question_about_price: 'Ի՞նչ գնով եք նախընտրում վաճառել տվյալ անշարժ գույքը։',
  price: 'Արժեք',
  property_price: 'Գույքի Արժեք',
  add_info: 'Լրացուցիչ տեղեկություններ',
  facilities: 'Կոմունալ հարմարություններ',
  property_description: 'Նկարագրություն',
  description: 'Նկարագրություն',
  yes: 'Այո',
  no: 'Ոչ',
  question_about_broker: 'Ցանկանու՞մ եք, որ Ձեր անշարժ գույքի վաճառքով/վարձակալությամբ զբաղվի գործակալը։',
  question_about_change_broker: 'Ցանկանու՞մ եք, որ Ձեր անշարժ գույքի վաճառքով/վարձակալությամբ զբաղվի այլ գործակալը։',
  agree_question: 'Համաձայն եմ առաջարկվող ընդհանուր դրույթներին ու պայմաններին։',
  publish_listing: 'Տեղադրել',
  general_description: 'Ընդհանուր նկարագիր',
  text_example_property: 'Նախադիտում',
  list_property_for: 'Հայտարարության նպատակը',
  what_do_you_want_to_sell: 'Ի՞նչ եք վաճառում',
  add_more: '+ Ավելացնել',
  zero_condition: 'Զրոյական',
  good: 'Լավ',
  excellent: 'Գերազանց',
  basement: 'Նկուղ',
  monolith: 'Մոնոլիտ',
  stone: 'Քարե',
  panel: 'Պանել',
  other: 'Այլ',
  profile: 'Հաշիվ',
  my_announcements: 'Պատմություն',
  settings: 'Կարգավորումներ',
  favorites: 'Ընտրվածներ',
  request: 'Հարցում',
  comment: 'Մեկնաբանություն',
  send: 'Ուղարկել',
  see_all_agents: 'Տեսնել բոլոր գործակալներին',
  land_area: 'Տնամերձ տարածք',
  area: 'Բնակելի մակերես',
  land_geometric: 'Հողի երկրաչափական տեսք',
  purpose: 'Օգտագործման նպատակը',
  public_construction_of_settlements: 'Բնակավայրերի հասարակական կառուցապատում',
  residential_construction_of_settlements: 'Բնակավայրերի բնակելի կառուցապատում',
  degrees: 'Աստիճան թեք',
  smooth: 'Հարթ',
  mixed_construction_of_settlements: 'Բնակավայրերի խառը կառուցապատում',
  front_position: 'Ճակատային դիրք',
  primary_and_secondary: 'Գլխավոր և երկրորդական',
  secondary: 'Երկրորդական',
  primary: 'Գլխավոր',
  front_position_length: 'Ճակատային դիրքի երկարություն',
  road_type: 'Ճանապարհի տեսակ',
  asphalt: 'Ասֆալտապատ',
  ground: 'Գրունտային',
  fence_type: 'Ցանկապատի տեսակ',
  partly_fenced: 'Մասամբ պարսպապատ',
  stone_fence: 'Քարե պարիսպ',
  no_fence: 'Չունի պարիսպ',
  infrastructure: 'Ենթակառուցվածքներ',
  all_available: 'Առկա է ամեն ինչ',
  no_communication: 'Չունի անցկացված կոմունիկացիա',
  all_available_except_irrigation_water: 'Առկա բոլորը բացի ոռոգման ջրից',
  balcony: 'Պատշգամբ',
  year: 'Գույքի տարիք',
  before: 'Մինչև',
  cover: 'Ծածկ',
  degree: 'Վթարայնության աստիճան',
  daily_rent: 'Օրական',
  monthly_rent: 'Ամսական',
  wood: 'Փայտ',
  reinforced_concrete: 'Մոնոլիտ',
  see_the_list_of: 'Դիմեք անշարժ գույքի ոլորտի ամենափորձառու գործակալություններին։',
  estate_agencies: 'Անշարժ գույքի գործակալություններ',
  see_all_estate_agencies: 'Տեսնել բոլոր գործակալությունները',
  our_partners: 'Մեր գործընկերները',
  see_the_list_of_partners: 'Ծանոթացե՛ք մեզ հետ համագործակցող ընկերություններին։',
  see_the_agents: 'Անշարժ գույքի վստահելի գործակալներ',
  see_the_agents_who: 'Կատարեք անվտանգ գործարքներ ամենավստահելի գործակալների օգնությամբ։',
  certificate: 'Վկայական',
  property_type: 'Գույքի տեսակ',
  property_for: 'Փնտրում եմ',
  address_1sq: 'Անաստաս Միկոյան 2/3, Երևան',
  footer_agencies: 'Գործակալություններ',
  footer_announcements: 'Հայտարարություններ',
  faq: 'Հաճախ տրվող հարցեր',
  payment_and_tax: 'Վճարումներ և հարկեր',
  contact: 'Կապ',
  about: 'Մեր մասին',
  about_me: 'Իմ մասին',
  support_: 'Աջակցություն',
  help_center: 'Աջակցության կենտրոն',
  find_agent: 'Գտնել գործակալ',
  features: 'Մանրամասն',
  privacy_policy: 'Գաղտնիության քաղաքականություն',
  copyright: '2021, 1SQ Realty: Բոլոր իրավունքները պաշտպանված են։',
  sofast: 'SoFast Solutions Inc.',
  powered_by: 'Ստեղծված է ',
  suffix_for_powered: '-ի կողմից։',
  price_is_require: 'Գինը պարտադիր է լրացնել',
  area_is_require: 'Հողի մակերեսը պարտադիր է լրացնել',
  front_position_length_is_require: '',
  address_is_require: 'Հասցեն պարտադր է լրացնել',
  building_is_require: 'Դաշտը պարտադիր է',
  description_is_require: 'Նկարագրությունը պարտադիր է լրացնել',
  min_3_image: 'Min 3 նկար',
  agree_is_require: 'Համաձայնությունը պարտադիր է',
  property_name_is_require: 'Դաշտը պարտադիր է',
  require: 'Դաշտը պարտադիր է',
  choose: 'Ընտրել',
  building: 'Շինություն',
  bath: 'Սան․',
  bed: 'Ննջ․',
  open_balcony: 'Բաց պատշգամբ',
  close_balcony: 'Փակ պատշգամբ',
  no_balcony: 'Չունի պատշգամբ',
  bad: 'Վատ',
  middle: 'Միջին',
  certificate_info: 'Սեփականության վկայականի ՊԱՏՃԵ (տեսանելի է միայն կայքի ադմինիստրատորին)',
  property_name_example: 'Անվանումը',
  there_is_building: 'Առկա է շինություն',
  there_is_not_building: 'Առկա չէ շինություն',
  building_number: 'Բն.',
  yearly_rent: 'Տարեկան',
  more: 'Մանրամասն',
  avaliable: 'Բնակության վայրերի քանակություն։',
  broker_owner: 'Գործ․ / Սեփ․',
  owner: 'Սեփականատեր',
  broker: 'Գործակալ',
  reset: 'Թարմացնել',
  rooms: 'Սենյակներ:',
  parking: 'Կայանատեղիներ:',
  floor_height: 'Առաստաղի բարձրությունը:',
  m: 'մ',
  lot: 'Լոտ:',
  elevator: 'Վերելակ:',
  gas: 'Գազ:',
  pharmacy: 'Դեղատուն:',
  garden: 'Այգի:',
  mall: 'Մոլ:',
  available2: 'Առկա է։',
  no_available: 'Առկա չէ։',
  region: 'Տարածաշրջան',
  aragatsotn: 'Արագածոտն',
  ararat: 'Արարատ',
  armavir: 'Արմավիր',
  gegharkunik: 'Գեղարքունիք',
  kotayk: 'Կոտայք',
  lori: 'Լոռի',
  shirak: 'Շիրակ',
  syunik: 'Սյունիք',
  tavush: 'Տավուշ',
  vayoc_dzor: 'Վայոց ձոր',
  yerevan: 'Երևան',
  company: 'Ընկերություն',
  service_type: 'Ծառայության տեսակը',
  select_agent: 'Ընտրեք Գործակալ',
  broker_page_title: 'Անշարժ գույքի գործակալներ Հայաստանում',
  top_agencies: 'Անշարժ գույքի լավագույն գործակալությունները',
  rating: 'Վարկանիշ',
  your_property: 'Ձեր գույքը',
  rentings: 'Վարձույթ',
  offers_and_closings: 'Առաջարկներ և փակումներ',
  role: 'Նշանակությունը',
  created_date: 'Ստեղծման ամսաթիվը',
  status: 'Կարգավիճակ',
  feedback: 'Հետադարձ կապ',
  reviews: 'Կարծիքներ',
  professional_information: 'Մասնագիտական տեղեկատվություն',
  cell_phone: 'Հեռախոս',
  websites: 'Սոցկայքեր',
  member_since: 'Կայքում է',
  licenses: 'Թույլտվություն',
  overall_rating: 'Ընդհանուր վարկանիշ',
  call_now: 'Զանգահարել',
  property: 'Գույք',
  are_you_sure: 'Համողզվա՞ծ եք',
  you_want_to_delete: 'Ցանկանու՞մ եք ջնջել հայտարարությունը',
  select_auto: 'Ավտոմատ կերպով',
  cancel: 'Չեղարկել',
  close: 'Փակել',
  welcome: 'Բարի գալուստ 1SQ!',
  sign_in: 'Մուտք',
  sign_up: 'Գրանցվել',
  continue_registration: 'Շարունակել գրանցումը',
  email: 'էլ. հասցե',
  password: 'Գաղտնաբառ',
  forgot_password: 'Մոռացե՞լ եք գաղտնաբառը',
  confirm_password: 'Հաստատել գաղտնաբառը',
  first_name: 'Անուն',
  last_name: 'Ազգանուն',
  send_link: 'Ուղարկել հղումը',
  name_lastname: 'Անուն Ազգանուն',
  request_description: 'Խնդրում եմ ուղարկել ինձ մանրամասն տեղեկություններ այս գույքի մասին:',
  edit_profile: 'Խմբագրել',
  edit: 'Խմբագրել',
  contact_information: 'Կոնտակտային տվյալներ',
  phone_number: 'Հեռ․',
  personal_information: 'Անձնական տվյալներ',
  new_password: 'Նոր գաղտնաբառ',
  sign_in_security: 'Մուտք և անվտանգություն',
  general_area: 'Մակերես',
  land_type: 'Նշանակություն',
  property_place: 'Գույքի դիրքը',
  into_building: 'Շինության ներսում',
  out_of_building: 'Առանձին շինություն',
  sewer: 'Կոյուղի',
  distance_from_metro_station: 'Հեռավորությունը մետրոյից',
  distance_from_medical_center: 'Մոտակա հիվանդանոց',
  distance_from_stations: 'Մոտակա կանգառ',
  distance_from_supermarket: 'Մոտակա սուպերմարկետ',
  distance_from_school: 'Մոտակա դպրոց',
  distance_from_kindergarten: 'Մոտակա Մանկապարտեզ',
  distance_from_pharmacy: 'Մոտակա դեղատուն',
  furniture: 'Կահույք',
  individual: 'Առանձին',
  centralised: 'Կենտրոնացված',
  shops: 'Խանութներ',
  offices: 'Գրասենյակներ',
  services: 'Ծառայություններ',
  update: 'Թարմացնել',
  search: 'Որոնել',
  modal_title_1: 'Գտնել առաջարկներ',
  modal_title_2: 'Ընտրեք նախընտրելի տարածաշրջանն ու քարտեզի օգնությամբ դիտեք Ձեզ առավել հարմար առաջարկները։',
  phone_number_verification: 'Հաստատեք Ձեր հեռախոսահամարը',
  verify: 'Հաստատել',
  get_code: 'Հաստատել SMS-ի միջոցով',
  fed_text: 'Շնորհակալություն, ձեր հեռախոսահամարը հաստատված է',
  accept: 'Ընդունել',
  all: 'Բոլորը',
  phone: 'Հեռախոսահամար',
  code: 'Կոդ',
  verification_code: 'Հաստատման կոդ',
  sale: 'Վաճառք',
  Sale: 'Գնել',
  start_date: 'Մեկնարկի ամսաթիվ',
  end_date: 'Ավարտման ամսաթիվը',
  main_image: 'Հիմնական նկար',
  edit_listing: 'Փոխել',
  basic_info: 'Տեղեկատվություն',
  email_address: 'Էլ.հասցե',
  email_not_valid: 'Սխալ Էլ․ հասցե',
  password_not_valid: 'Սխալ գաղտնաբառ',
  confirm_password_error: 'Գաղտնաբառը չի համընկնում',
  password_confirmation_require_error: 'Գաղտնաբառի հաստատումը պարտադիր է',
  first_name_error: 'Դաշտը պարտադիր է',
  last_name_error: 'Դաշտը պարտադիր է',
  email_error: 'Տվյալ էլ․ հասցեով օգտատեր արդեն գոյություն ունի',
  password_min_error: 'Գաղտնաբառը պետք է պարունակի տառեր և թվեր, առնվազն 8 նիշ',
  password_info: '(Պետք է պարունակի տառեր և թվեր՝ առնվազն 8 նիշ)',
  email_require_error: 'Դաշտը պարտադիր է',
  password_require_error: 'Դաշտը պարտադիր է',
  email_or_password_wrong: 'Սխալ էլ․ հասցե կամ գաղտնաբառ',
  condominium: 'Համատիրության վճար',
  what_do_you_want_to_rent: 'Ի՞նչ եք հանձնում վարձակալության։',
  password_required: '(Գաղտնաբառի փոփոխելիս անհրաժեշտ է հինը)',
  new_password_required: '(Գաղտնաբառի Պարտադիր է)',
  no_favorite: 'Արդյունքներ չկան',
  contacts: 'Հետադարձ կապ',
  notification: 'Ծանուցում',
  no_result: 'Արդյունքներ չկան',
  apartments: 'Բնակարաններ և հարկեր',
  renovation: 'Վերանորոգում',
  overview: 'Նկարագիր',
  today: 'Այսօր',
  contact_agent: 'Կապ գործակալի հետ',
  contact_owner: 'Կապ սեփականատիրոջ հետ',
  published_by: 'Հրապարակել է',
  view_more: 'Տեսնել ավելին',
  continue: 'Շարունակել',
  auto_choose: 'Ավտոմատ ընտրություն',
  choose_agent: 'Կցել գործակալի',
  broker_choose_text: 'Ձեր հայտարարությունը կկցվի այն գործակալին, ով առաջինը կընտրի այն։',
  currency: 'Արժույթ',
  finish_listing: 'Վստա՞հ եք, որ ցանկանում եք հրապարակել հայտարարությունը։',
  verify_listing: 'Ձեր հայտը հաստատման փուլում է։ Խնդրում ենք սպասել պատասխան նամակի 24 ժամվա ընթացքում։',
  const_agency: 'Գործակալություն',
  search_places: 'Որոնել․․․',
  similar_listing: 'Նման առաջարկներ',
  verified_text: 'Շնորհակալություն, Ձեր հեռախոսահամարը հաստատված է',
  additional: 'Լրացուցիչ',
  no_metro: 'Չկա կանգառ',
  busy_date: 'Զբաղված օրեր',
  no_sewer: 'Բացակայում է',
  message_sending: 'Հաղորդագրությունն ուղարկված է։',
  description_size_error: 'Առավելագույնը 500 տառ',
  back: 'Հետ',
  request_text: 'Հղումն ուղարկված է Ձեր էլ. հասցեին',
  ready: 'Պատրաստ է',
  sold: 'Վաճառված է',
  air_polution: 'Օդի խոնավություն',
  air_polution_types: {
    1: 'Լավ',
    2: 'Միջին',
    3: 'Բավարար',
    4: 'Վատ',
    5: 'Շատ վատ',
  },
  message: 'Հաղորդագրություն',
  messages: 'Հաղորդագրություններ',
  const_percent_text: 'Նախագծի աշխատանքները շարունակվում են։',
  reset_password: 'Վերականգնել գաղտնաբառը',
  live_video: 'Օնլայն դիտում',
  as_an_agent: 'Գրանցվել որպես գործակալ',
  no_messages: 'Հաղորդագրություններ չկան',
  sort_by: 'Դասավորել ըստ',
  price_high_low: 'Գնի նվազման',
  price_low_high: 'Գնի աճման',
  date_newest_first: 'Ամսաթվի (նորեր)',
  date_oldest_first: 'Ամսաթվի (հներ)',
  furniture_available: 'Առկա է',
  furniture_no_available: 'Առկա չէ',
  companies: 'Ընկերություններ',
  completion_deadlines: 'Ժամկետներ',
  finished: 'Ավարտված',
  current: 'Ընթացիկ',
  send_request: 'Ուղարկել հարցում',
  request_success_text:
    'Շնորհակալություն մեզ հետ կապ հաստատելու համար։ Մենք կպատասխանենք Ձեզ 3 աշխատանքային օրվա ընթացքում։',
  listing_limit_error: 'Դուք արդեն ունեք 5 հայտարարություն',
  account_verified: 'Ստուգված հաշիվ',
  gallery: 'Պատկերասրահ',
  mark_all_as_read: 'Նշեք բոլորը որպես կարդացված',
  choose_an_agent: 'Ընտրեք Գործակալ',
  phone_required: 'Դաշտը պարտադիր է',
  phone_unique: 'Հեռախոսահամարն արդեն գրանցված է',
  results: 'արդյունք',
  unverified_announcements: 'Չհաստատված',
  open_map: 'Քարտեզ',
  open_list: 'Ցուցակ',
  user_not_exist: 'Օգտատերը գոյություն չունի',
  archived_announcements: 'Արխիվ',
  complete: 'Ավարտել',
  completed: 'Ավարտված',
  pending: 'Սպասվում է',
  confirmed: 'Հաստատված',
  cancelled: 'Չեղարկված',
  rejected: 'Մերժված',
  reject: 'Մերժել',
  archive: 'Արխիվացնել',
  renew: 'Թարմացնել',
  archived: 'Արխիվացված',
  comments: 'Մեկնաբանություններ',
  rate_the_agent: 'Գնահատեք գործակալին',
  choose_a_company: 'Ընտրեք գործակալություն',
  choose_auto: 'Ավտոմատ ընտրություն',
  auto_choose_broker: 'Ավտոմատ',
  choose_auto_broker: 'ընտրություն',
  properties: 'Գույքեր',
  brokers: 'Գործակալներ',
  new_building: 'Նորակառույց',
  contact_company: 'Կապ գործակալության հետ',
  meta_home_title: 'Վարձով Բնակարան | Բնակարանների Վաճառք | Անշարժ Գույք | 1SQ Realty',
  meta_home_title1: '1SQ Realty',
  meta_home_description:
    '1SQ Realty-ն կօգնի Ձեզ գնել, վաճառել կամ վարձակալել ցանկացած անշարժ գույք ինչպես Երևանի տարածքում, այնպես էլ մարզերում։ Մանրամասների համար՝ +374 44 800 455։',
  meta_agents_list: 'Անշարժ Գույքի Գործակալներ | Վաճառք և Վարձակալություն | 1SQ Realty',
  meta_agents_list_description:
    '1SQ Realty-ն համագործակցում է Հայաստանի անշարժ գույքի շուկայի լավագույն գործակալների ու գործակալությունների հետ՝ ստեղծելով ոլորտում ամենավստահելի օնլայն հարթակը։',
  meta_announcements: 'Անշարժ Գույքի Հայտարարություններ | Վաճառք և Վարձակալություն | 1SQ Realty',
  meta_announcements_description:
    'Տեղադրեք անվճար հայտարարություններ Հայաստանի անշարժ գույքի ամենամեծ հարթակում։ Գտեք գնորդ կամ վարձակալ ամենասեղմ ժամկետներում։',
  meta_agencies_list: 'Անշարժ Գույքի Գործակալություններ Հայաստանում  | 1SQ Realty',
  meta_agencies_list_description:
    '1SQ Realty հարթակում գրանցված են միայն վստահելի գործակալություններ, որոնք լավագույնն են Հայաստանի անշարժ գույքի շուկայում։',
  meta_construction: 'Բնակարաններ Կառուցապատողից | Անշարժ Գույքի Վաճառք | 1SQ Realty',
  meta_construction_description:
    'Փնտրեք բնակարաններ կառուցապատողից այստեղ։ 1SQ Realty-ն հնարավորություն է տալիս տեսնել Ձեր ապագա բնակարանի կառուցման և վերանորոգման բոլոր մանրամասները։',
  meta_agency_info: 'Անշարժ Գույքի Գործակալություն | 1SQ Realty',
  choose_currency: 'Ընտրեք արժույթը',
  average_value: 'Միջ․ արժեք',
  estimate: 'Կադ․ արժեք',
  mortgage: 'Հիփոթեքի հաշվիչ',
  salary: 'Աշխատավարձի հաշվիչ',
  persent: 'Տոկոսադրույք',
  loan_amount: 'Վարկի գումարի չափը',
  loan_prepayment: 'Վարկի կանխավճարի չափը',
  it_tax: 'Արդյո՞ք աշխատում եք ՏՏ ոլորտի հարկային արտոնություններից օգտվող ընկերությունում։',
  income_tax: 'Եկամտային հարկ',
  IT: 'ՏՏ',
  social_fee: 'Սոցիալական վճար',
  stamp_duty: 'Դրոշմանիշային վճար',
  net_balance: 'Մաքուր Մնացորդ',
  guyqahark: 'Գույքահարկի հաշվիչ',
  grafik: 'Տեսնել գրաֆիկը',
  business_analitica: 'Բիզնես վերլուծություն',
  guyqi_ekamtaberutyun: 'Գույքի եկամտաբերություն',
  rent_range: 'Ամսավարձ',
  business_info: 'Եկամտաբերություն',
  month: 'Ամիս',
  mortgage_calculator: 'Հիփոթեքի հաշվիչ',
  term: 'Ժամկետ',
  monthly_fee: 'Ամսական Վճար',
  annual_interest_rate: 'Տարեկան տոկոսադրույք',
  appartment_price: 'Բն․ արժեքը',
  payment: 'Վճար',
  change: 'Փոփոխել',
  calculate: 'Հաշվել',
  fee: 'Տոկոսադրույք',
  mother: 'Մայր գումար',
  annuity: 'Ամսեկան վճար',
  mg_warning: '"Ամիս" դաշտը պարտադիր է',
  individual_user: 'Անհատ',
};
