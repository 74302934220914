export const TRANSLATIONS_RU = {
  buy: 'Купить',
  sell: 'Продать',
  rent: 'Снять',
  Rent: 'Снять',
  sell_or_rent: 'Продать / Снять',
  rent_type: 'Тип аренды',
  rental_period: 'Срок аренды',
  my_bookings: 'Мои Бронирования',
  booking_requests: 'Запросы На Бронирование',
  book: 'Бронировать',
  cancel_booking_confirmation: 'Вы хотите отменить бронирование?',
  reject_booking_confirmation: 'Вы хотите отклонить бронирование?',
  confirm_booking_confirmation: 'Вы хотите подтвердить бронирование?',
  booking_confirmation: 'Подтверждение: это выбранные вами даты для бронирования?',
  booked_successfully: 'Ваша заявка находится на рассмотрении.',
  construction: 'Квартиры в новостройке',
  contact_us: 'Связаться',
  add_listing: 'Разместить объявление',
  login: 'Войти',
  logout: 'Выйти',
  name: 'Имя',
  surname: 'Фамилия:',
  property_name: 'Название имущества',
  number_of_bedrooms: 'Количество спален',
  property_size: 'Площадь недвижиости',
  ceiling_height: 'Высота потолка',
  number_of_bathrooms: 'Ванная комната',
  floor: 'Этаж',
  floors: 'Этажи',
  condition: 'Состояние',
  storeys: 'Количество этажей',
  building_type: 'Тип здания',
  address: 'Адрес ',
  info_address: 'Выберите адрес на карте',
  upload_photos: 'Загрузить картинку',
  min: 'Мин',
  max: 'Макс',
  images: 'Картинки',
  question_about_price: 'За какую цену вы рассчитываете продать эту недвижимость?',
  price: 'Цена',
  property_price: 'Цена недвижиости',
  add_info: 'Дополнительная информация',
  facilities: 'Комунальные удобства',
  property_description: 'Описание',
  description: 'Описание',
  yes: 'Да',
  no: 'Нет',
  question_about_broker: 'Хотите, чтобы арендой/продажей Вашей недвижимости занимался агент?',
  question_about_change_broker: 'Хотите. чтобы арендой/продажей Вашей недвижимости занимался другой агент?',
  agree_question: 'Я согласен(а) с предлагаемыми общими условиями.',
  publish_listing: 'Опубликовать',
  general_description: 'Общее описание',
  text_example_property: 'Предварительный просмотр',
  list_property_for: 'Цель объявления',
  what_do_you_want_to_sell: 'Что Вы хотите продать?',
  zero_condition: 'Нулевое',
  good: 'Хорошее',
  excellent: 'Отличное',
  basement: 'Подвал',
  monolith: 'Монолит',
  stone: 'Камень',
  panel: 'Панель',
  other: 'Другое',
  add_more: '+ Еще',
  profile: 'Аккаунт',
  my_announcements: 'История',
  favorites: 'Избранное',
  settings: 'Настройки',
  request: 'Запрос',
  comment: 'Комментарии',
  send: 'Отправить',
  see_all_agents: 'Посмотреть всех агентов',
  land_area: 'Участок возле дома',
  area: 'Жилая площадь',
  land_geometric: 'Геометрический вид земли',
  purpose: 'Цель использования',
  public_construction_of_settlements: 'Общественное строительство поселений',
  residential_construction_of_settlements: 'Жилищное строительство поселений',
  degrees: 'Градусов',
  smooth: 'Ровный',
  mixed_construction_of_settlements: ' Смешанное строительство поселений',
  front_position: 'Передняя позиция',
  primary_and_secondary: 'Главная и вторичная',
  secondary: 'Вторичная',
  primary: 'Главная',
  front_position_length: 'Длина переднего положения',
  road_type: 'Тип дороги',
  asphalt: 'Асфальт',
  ground: 'Грунт',
  fence_type: 'Тип забора',
  partly_fenced: 'Частичная огородка',
  stone_fence: 'Каменный забор',
  no_fence: 'Нет забора',
  infrastructure: 'Инфраструктура',
  all_available: 'Доступно все',
  no_communication: 'Дет коммуникаций',
  all_available_except_irrigation_water: 'Доступно все  кроме поливной воды',
  balcony: 'Балкон',
  year: 'Возраст недвижимости',
  before: 'До',
  cover: 'Покрытие',
  degree: 'Степень аварийности',
  daily_rent: 'В день',
  monthly_rent: 'В месяц',
  wood: 'Древесина',
  reinforced_concrete: 'Монолит',
  see_the_list_of: 'Свяжитесь с самыми опытными агентствами недвижимости.',
  estate_agencies: 'Агентства недвижимости',
  our_partners: 'Наши партнеры',
  see_the_list_of_partners: 'Познакомьтесь с сотрудничающими с нами компаниями.',
  see_the_agents: 'Проверенные агенты по недвижимости',
  see_the_agents_who: 'Совершайте безопасные сделки с помощью самых надежных агентов.',
  property_type: 'Тип недвижимости',
  property_for: 'Ищу',
  address_1sq: 'ул. Анастаса Микояна, 2/3, Ереван',
  footer_agencies: 'Агентства',
  footer_announcements: 'Объявления',
  faq: 'Часто задаваемые вопросы',
  payment_and_tax: 'Оплата и налоги',
  contact: 'Контакты',
  about: 'О нас',
  about_me: 'Обо мне',
  support_: 'Поддержка',
  help_center: 'Центр поддержки',
  find_agent: 'Найти агента',
  features: 'Подробно',
  privacy_policy: 'Политика приватности',
  copyright: '2021, 1SQ Realty. Все права защищены. ',
  sofast: 'SoFast Solutions Inc.',
  powered_by: 'Создано ',
  suffix_for_powered: '',
  buy_text: 'Покупайте любую недвижимость в кратчайшие сроки.',
  rent_text: 'Арендуйте любую недвижимость по выбранным Вами параметрам.',
  construction_text: 'Вы найдете самые удобные варианты по покупке недвижимости на первичном рынке.',
  sell_text: 'Продайте или сдайте в аренду свою недвижимость разместив бесплатное объявление.',
  banner_title1: 'Универсальная платформа недвижимости',
  banner_title2: '',
  certificate: 'Сертификат',
  price_is_require: 'Цена обязательна для заполнения',
  area_is_require: 'Площадь земли обязательна для заполнения',
  front_position_length_is_require: '',
  address_is_require: 'Адрес обязателен для заполнения',
  building_is_require: 'Обязательное поле',
  description_is_require: 'Описание обязательно для заполнения',
  min_3_image: 'Мин. 3 картинки',
  agree_is_require: 'Соглашение обязательно',
  property_name_is_require: 'Обязательное поле',
  require: 'Обязательное поле',
  banner_text1: 'Мы поможем купить, продать или арендовать любую недвижимость - быстро',
  banner_text2: 'и безопасно.',
  choose: 'Выбрать',
  building: 'Строение',
  bath: 'Ванных',
  bed: 'Спален',
  open_balcony: 'Открытый балкон',
  close_balcony: 'Закрыть балкон',
  no_balcony: 'Нет балкона',
  notification: 'Уведомление',
  bad: 'Плохое',
  middle: 'Среднее',
  rent_price: 'Оплата',
  certificate_info: 'Свидетельство о праве собственности (доступно только администратору сайта)',
  property_name_example: 'Название',
  there_is_building: 'Имеется строение',
  there_is_not_building: 'Нет строения',
  building_number: 'Кв.',
  yearly_rent: 'В год',
  available: 'Наличие мест в резиденции։',
  available2: 'Наличие мест։',
  more: 'Подробности',
  broker_owner: 'Агент / Влад.',
  owner: 'Владелец',
  broker: 'Агент',
  reset: 'Обновить',
  rooms: 'Комнаты:',
  parking: 'Парковочные места:',
  floor_height: 'Высота этажа:',
  m: 'м',
  lot: 'Лот:',
  elevator: 'Лифт:',
  gas: 'Газ:',
  pharmacy: 'Аптека:',
  garden: 'Сад:',
  mall: 'Молл:',
  region: 'Область',
  aragatsotn: 'Арагацотн',
  ararat: 'Арарат',
  armavir: 'Армавир',
  gegharkunik: 'Гехаркуник',
  kotayk: 'Котайк',
  lori: 'Лори',
  shirak: 'Ширак',
  syunik: 'Сюник',
  tavush: 'Тавуш',
  vayoc_dzor: 'Вайоц дзор',
  yerevan: 'Ереван',
  company: 'Компания',
  service_type: 'Тип Обслуживания',
  cancel: 'Отменить',
  close: 'Закрыть',
  select_auto: 'Автоматически',
  select_agent: 'Выбрать агента',
  broker_page_title: 'Агенты по недвижимости в Армении',
  top_agencies: 'Лучшие агентства',
  rating: 'Рейтинг',
  continue: 'Продолжить',
  your_property: 'Ваша недвижимость',
  rentings: 'Аренда',
  offers_and_closings: 'Предложения и закрытия',
  role: 'Назначение',
  created_date: 'Дата создания',
  status: 'Статус',
  feedback: 'Обратная связь',
  reviews: 'Отзывы',
  professional_information: 'Профессиональная информация',
  cell_phone: 'Мобильный',
  websites: 'Соц. сети',
  member_since: 'На сайте с',
  licenses: 'Лицензия',
  overall_rating: 'Общий рейтинг',
  call_now: 'Позвонить',
  property: 'Недвижимость',
  are_you_sure: 'Вы уверенны?',
  you_want_to_delete: 'Do You want to delete announcement?',
  welcome: 'Добро пожаловать в 1SQ!',
  sign_in: 'Вход',
  sign_up: 'Регистрация',
  continue_registration: 'Продолжить регистрацию',
  email: 'Эл. почта',
  password: 'Пароль',
  forgot_password: 'Забыли пароль?',
  confirm_password: 'Подтвердить пароль',
  first_name: 'Имя',
  last_name: 'Фамилия',
  send_link: 'Отправить ссылку',
  name_lastname: 'Имя Фамилия',
  request_description: 'Пожалуйста, вышлите более подробную информацию об этом объекте.',
  edit_profile: 'Редактировать',
  edit: 'Редактировать',
  contact_information: 'Контактная информация',
  phone_number: 'Тел․',
  personal_information: 'Персональная информация',
  new_password: 'Новый пароль',
  sign_in_security: 'Вход и безопасность',
  general_area: 'Площадь',
  land_type: 'Назначение',
  property_place: 'Местоположение недвижимости',
  into_building: 'Внутри построики',
  out_of_building: 'Отдельная постройка',
  sewer: 'Канализация',
  distance_from_metro_station: 'Расстояние от метро',
  distance_from_medical_center: 'Ближайшая больница',
  distance_from_stations: 'Ближайшая автобусная остановка',
  distance_from_supermarket: 'Ближайший супермаркет',
  distance_from_school: 'Ближайшая школа',
  distance_from_kindergarten: 'Ближайший детский сад',
  distance_from_pharmacy: 'Ближайшая аптека',
  furniture: 'Мебель',
  individual: 'Отдельная',
  centralised: 'Централизованная',
  shops: 'Магазины',
  offices: 'Офисы',
  services: 'Услуги',
  update: 'Обновить',
  search: 'Поиск',
  modal_title_1: 'Найдите предложения',
  modal_title_2: 'Выберите желаемый регион и просматривайте наиболее удобные предложения с помощью карты',
  phone_number_verification: 'Подтвердите ваш номер телефона',
  verify: 'Подтвердить',
  get_code: 'Подтвердить через SMS',
  verified_text: 'Спасибо, ваш номер телефона проверен',
  phone: 'Номер телефона',
  code: 'Код',
  verification_code: 'Проверочный код',
  sale: 'Продажа',
  Sale: 'Продажа',
  start_date: 'Дата начала',
  end_date: 'Дата окончания',
  accept: 'Принять',
  main_image: 'Основное изображение',
  edit_listing: 'Редактировать',
  basic_info: 'Основная информация',
  email_address: 'Электронная почта Адрес',
  email_not_valid: 'Неправильная эл. почта',
  password_not_valid: 'Неправильный пароль',
  password_confirmation_require_error: 'Подтверждение пароля обязательно',
  confirm_password_error: 'Пароль не совпадает',
  first_name_error: 'Обязательное поле',
  last_name_error: 'Обязательное поле',
  email_error: 'Пользователь с данной эл. почтой уже существует',
  email_require_error: 'Обязательное поле',
  email_invalid: 'Обязательное поле',
  password_require_error: 'Обязательное поле',
  password_min_error: 'Пароль должен включать буквы и цифры, не менее 8 символов',
  password_info: '(Должен включать буквы и цифры, не менее 8 символов)',
  email_or_password_wrong: 'Неправильная эл. почта или пароль',
  condominium: 'Плата за кондоминиум',
  password_required: '(Для установления нового пароля нужно ввести старый пароль)',
  new_password_required: '(Пароль Обязательно)',
  what_do_you_want_to_rent: 'Что ты хочешь сдать в аренду?',
  no_favorite: 'Нет результатов',
  contacts: 'Контакты',
  no_result: 'Нет результатов',
  apartments: 'Квартиры и этажи',
  renovation: 'Ремонт',
  auto_choose: 'Автоматический выбор',
  choose_agent: 'Передать агенту',
  broker_choose_text: 'Ваше объявление будет доверено агенту, который выберет его первым.',
  currency: 'Валюта',
  finish_listing: 'Вы уверены, что хотите опубликовать объявление?',
  verify_listing: 'Ваша заявка находится на рассмотрении. Мы ответим Вам в течение 24 часов.',
  overview: 'Обзор',
  today: 'Сегодня',
  contact_agent: 'Связаться с агентом',
  contact_owner: 'Связаться с владельцем',
  published_by: 'Опубликовал',
  view_more: 'Показать еще',
  const_agency: 'Агентство',
  search_places: 'Поиск․․․',
  similar_listing: 'Похожие предложения',
  additional: 'Дополнительно',
  no_metro: 'Нет станции метро',
  busy_date: 'Напряженные дни',
  no_sewer: 'Нет на месте',
  message_sending: 'Письмо отправлено',
  description_size_error: 'Макс 500 букв',
  back: 'Назад',
  request_text: 'Запрос отправлен на Ваш эл. адрес',
  ready: 'Готова',
  sold: 'Продано',
  air_polution: 'Влажность воздуха',
  air_polution_types: {
    1: 'Хорошо',
    2: 'Удовлетворительно',
    3: 'Умеренно',
    4: 'Плохо',
    5: 'Очень плохо',
  },
  reset_password: 'Восстановить пароль',
  live_video: 'Онлайн просмотр',
  message: 'Сообщение',
  messages: 'Сообщения',
  const_percent_text: 'Работы над проектом продолжаются.',
  as_an_agent: 'Зарегистрируйтесь как агент/брокер',
  no_messages: 'Нет сообщений',
  sort_by: 'Сортировать по',
  price_high_low: 'Убыванию цены',
  price_low_high: 'Возрастанию цены',
  date_newest_first: 'Дате (новые)',
  date_oldest_first: 'Дате (старые)',
  furniture_available: 'Доступен',
  furniture_no_available: 'Недоступен',
  send_request: 'Отправить запрос',
  request_success_text: 'Спасибо, что связались с нами, мы ответим в течение трех рабочих дней.',
  companies: 'Компании',
  all: 'Все',
  completion_deadlines: 'Сроки',
  finished: 'Законченные',
  current: 'Текущий',
  listing_limit_error: 'У Вас уве есть 5 заявок',
  account_verified: 'Аккаунт верифицирован',
  gallery: 'Галерея',
  mark_all_as_read: 'Отметить все как прочитанные',
  choose_an_agent: 'Выберите Агента',
  phone_required: 'Поле телефона обязательно для заполнения',
  phone_unique: 'Телефонный номер уже зарегистрирован',
  results: 'результатов',
  unverified_announcements: 'Не подтверждено',
  open_map: 'Карта',
  open_list: 'Список',
  user_not_exist: 'Пользователь не существует',
  archived_announcements: 'Архив',
  complete: 'Заканчивать',
  completed: 'Завершенный',
  pending: 'В ожидании',
  confirmed: 'Подтвержденный',
  cancelled: 'Отменено',
  rejected: 'Отклоненный',
  reject: 'Отклонить',
  archive: 'Архив',
  renew: 'Обновить',
  archived: 'В архиве',
  comments: 'Комментарии',
  rate_the_agent: 'Оцените агента',
  choose_a_company: 'Выберите агентство',
  see_all_estate_agencies: 'Все агенства',
  choose_auto: 'Автоматический выбор',
  auto_choose_broker: 'Автоматический',
  choose_auto_broker: 'выбор',
  properties: 'Недвижимости',
  brokers: 'Агенты',
  new_building: 'Новостройка',
  contact_company: 'Связаться с агентством',
  meta_home_title: 'Аренда Квартиры | Купить Квартиру В Ереване | Недвижимость | 1SQ Realty',
  meta_home_title1: '1SQ Realty',
  meta_home_description:
    '1SQ Realty поможет вам купить, продать или арендовать любую недвижимость как в Ереване, так и в регионах. Подробности по телефону +374 44 800 455.',
  meta_agents_list: 'Агенты По Недвижимости | Продажа и Аренда Квартир | 1SQ Realty',
  meta_agents_list_description:
    '1SQ Realty сотрудничает с лучшими агентами и агентствами на рынке недвижимости Армении, создавая самую надежную онлайн-платформу в этой сфере.',
  meta_announcements: 'Объявления о Недвижимости | Аренда И Продажа Квартир | 1SQ Realty',
  meta_announcements_description:
    'Размещайте бесплатные объявления на крупнейшей платформе недвижимости в Армении. Найдите покупателя или арендатора в кратчайшие сроки.',
  meta_agencies_list: 'Агентства Недвижимости в Армении | 1SQ Realty',
  meta_agencies_list_description:
    '1SQ Realty регистрирует только проверенные агентства, которые считаются лучшими на рынке недвижимости Армении.',
  meta_construction: 'Квартиры от Застройщика | Недвижимость на Продажу | 1SQ Realty',
  meta_construction_description:
    'Здесь представлены новостройки. 1SQ Realty позволяет увидеть все нюансы строительства и ремонта Вашей будущей квартиры.',
  meta_agency_info: 'Агентство Недвижимости | 1SQ Realty',
  choose_currency: 'Выбрать валюту',
  average_value: 'Средняя стоимость',
  estimate: 'Кадастровая стоимость',
  mortgage: 'Ипотечный калькулятор',
  salary: 'Калькулятор заработной платы',
  persent: 'Ипотечный процент',
  loan_amount: 'Сумма кредита',
  loan_prepayment: 'Предоплата',
  it_tax: 'Работаете ли Вы в ИТ компании которая пользуется налоговой привилегией?',
  income_tax: 'Подоходный налог',
  IT: 'ИТ',
  social_fee: 'Социальный сбор',
  stamp_duty: 'Гербовый сбор',
  net_balance: 'Чистый баланс',
  guyqahark: 'Ипотечный калькулятор',
  grafik: 'Посмотреть график',
  business_analitica: 'Бизнес аналитика',
  guyqi_ekamtaberutyun: 'Прибыльность',
  rent_range: 'Цена аренды',
  business_info: 'Подсчитайте, сколько лет потребуется, чтобы иметь возможность купить такую же квартиру.',
  month: 'месяцев',
  mortgage_calculator: 'Ипотечный калькулятор',
  term: 'Срок',
  monthly_fee: 'Месячная оплата',
  annual_interest_rate: 'Годовой процент',
  appartment_price: 'Стоимость кв.',
  payment: 'Предоплата',
  change: 'Поменять',
  calculate: 'Посчитать',
  fee: 'Процентная ставка',
  mother: 'Основная оплата',
  annuity: 'Месячная оплата',
  mg_warning: 'Поле "Месяц" обязательное',
  individual_user: 'Индивидуальный пользователь',
};
